import React, { useEffect } from "react"
import Header from "../components/Header"
import PreHero from "./PreHero"
import { Link } from "react-router-dom"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="light bg-gradient-to-r from-[#F8DE4B]  to-[#EFBB27]">
				<Header />
				<PreHero />
				<div className="bg-norepeat  relative mt-6  w-full bg-cover bg-center bg-repeat-y pt-[0px] lg:mt-[40px]  lg:pt-[75px]">
					<div className=" mx-auto max-w-[1400px] pt-12 text-center text-black lg:pt-6">
						<h3 className="mb-12 mt-4 px-4 font-FuturaBold text-[20px] uppercase lg:mt-0 lg:px-0 lg:text-[42px]">Die Daten wurden erfolgreich übermittelt.</h3>
						<p className="mb-12 font-FuturaBook lg:text-[24px]">
							Du erhältst per E-Mail eine Eingangsbestätigung. <br />
							Anschließend werden wir die Einhaltung der Teilnahmevoraussetzungen prüfen.
						</p>
						<p className="mb-12 font-FuturaBook lg:mb-14 lg:text-[24px]">
							Viele Grüße <br />
							Dein YoPRO Team
						</p>
						<div className="pb-12">
							<Link to="/" className="btn mt-24 mb-24 bg-red py-12 font-FuturaBold text-[24px] uppercase text-white">
								{" "}
								Zurück zur Aktion
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Confirm
