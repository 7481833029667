import * as React from "react"

interface PreHeroProps {
	type?: any
}
const PreHero: React.FC<PreHeroProps> = ({ type }) => {
	return (
		<section className="lg:bg-center-bottom  relative top-0 z-10 mx-auto  h-[460px] w-full lg:h-[610px] lg:bg-red">
			<div className="mx-auto grid h-[250px] max-w-[1400px] bg-red py-8 lg:grid-cols-2 lg:px-12 lg:py-24 ">
				<div className="relative justify-self-end px-6 py-6 lg:px-0 lg:py-0">
					<div className="mt-8 px-2 py-0 lg:mt-12 lg:px-0 lg:py-0">
						<p className="font-Veener text-center text-[24px] text-[#F8DE4B] md:text-[2px] lg:text-[42px] xl:text-[62px]">
							<span className="font-FuturaBold uppercase ">
								Vielen Dank für die Teilnahme <br />
							</span>{" "}
							<span className="font-FuturaBook uppercase text-[#F8DE4B] ">an unserer GRATIS-TESTEN-AKTION.</span>
						</p>
					</div>
				</div>
				<div>
					<img
						src={process.env.PUBLIC_URL + "/images/danone/over-products.png"}
						className="z-2  relative mx-auto mt-12 max-w-[90%]  lg:mt-72   lg:ml-4 lg:w-[1800px] lg:max-w-full xl:mt-44 xl:w-[1500px]"
					/>
				</div>
			</div>
		</section>
	)
}

export default PreHero
